<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>Pedidos em Cobrança</h4>
          </el-row>
        </el-col>
        <div>
          <el-button
            type="primary"
            size="medium"
            @click="updateOrder({ uid: order.uid, should_charge: true })"
            :disabled="!order"
          >
            Adicionar na cobrança</el-button
          >
          <el-popconfirm
            @confirm="removeAllOrdersFromCharge"
            title="
              Tem certeza?
            "
            ><template #reference>
              <el-button
                type="danger"
                size="medium"
                :loading="isLoadingClearButton"
              >
                Limpar cobrança
              </el-button></template
            ></el-popconfirm
          >
        </div>
      </el-row>
    </template>
    <base-input
      v-model="searchOrder"
      v-on:keyup="fetchOrder"
      label="Numero do Pedido:"
      style="margin-bottom: 16px"
    >
    </base-input>
    <el-row type="flex" :gutter="8">
      <el-col :md="12" :xs="24">
        <el-descriptions
          border
          :column="1"
          title="Resumo de Pedidos em Cobrança"
          direction="horizontal"
        >
          <el-descriptions-item>
            <template #label> Total de Pedidos </template>
            {{ Orders?.length }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Valor Total dos Pedidos </template>
            {{ currencyFormatter.format(sumTotalAmount()) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> Valor Total Pago </template>
            {{ currencyFormatter.format(sumTotalPaidPayments(OrdersPayments)) }}
          </el-descriptions-item>
          <el-descriptions-item class-name="danger">
            <template #label> Valor Total em Aberto </template>
            {{
              currencyFormatter.format(
                sumTotalPayments(OrdersPayments) -
                  sumTotalPaidPayments(OrdersPayments)
              )
            }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :md="12" :xs="24">
        <el-empty
          v-if="!order"
          description="Nenhum pedido para mostrar aqui"
        ></el-empty>
        <div v-else>
          <el-descriptions
            border
            :column="2"
            title="Detalhes do Pedido"
            direction="vertical"
          >
            <el-descriptions-item>
              <template #label> Romaneio </template>
              {{ order?.invoice_id }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label> Cliente </template>
              {{ order?.client?.legal?.social_name || order?.client?.name }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label> Valor Total </template>
              {{ currencyFormatter.format(Number(order?.total_amount) || 0) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label> Valor Pago </template>
              {{
                currencyFormatter.format(Number(sumTotalPaidPayments()) || 0)
              }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-col>
    </el-row>

    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Orders"
      style="width: 100%; z-index: 0"
    >
      <el-table-column label="romaneio" prop="invoice_id" width="100">
      </el-table-column>
      <el-table-column label="código" prop="seq" width="100"></el-table-column>
      <el-table-column label="pedido" prop="code"></el-table-column>
      <el-table-column prop="driver.employee.name" label="vendedor">
      </el-table-column>
      <el-table-column prop="client.name" label="cliente">
        <template #default="{ row: order }">
          {{ order.client?.legal?.social_name || order.client?.name }}
        </template>
      </el-table-column>
      <el-table-column prop="client.legal.company_name" label="razão social">
      </el-table-column>
      <el-table-column label="data pedido" width="100">
        <template #default="{ row: order }">
          <span>
            {{
              order.created_at
                ? dateFormatter.format(new Date(order.created_at))
                : "-"
            }}
          </span>
          {{ " " }}
          <span>
            {{
              order.created_at
                ? timeFormatter.format(new Date(order.created_at))
                : "-"
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="data entrega" width="100">
        <template #default="{ row: order }">
          <span>
            {{
              order.delivered_at
                ? dateFormatter.format(new Date(order.delivered_at))
                : "NÃO ENTREGUE"
            }}
          </span>
          {{ " " }}
          <span>
            {{
              order.delivered_at
                ? timeFormatter.format(new Date(order.delivered_at))
                : ""
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="NF" prop="invoice.nfe"></el-table-column>
      <el-table-column
        label="valor"
        :formatter="
          ({ total_amount }) => currencyFormatter.format(Number(total_amount))
        "
      ></el-table-column>
      <el-table-column
        label="pago"
        :formatter="
          ({ payments }) =>
            currencyFormatter.format(sumTotalPaidPayments(payments))
        "
      ></el-table-column>
      <el-table-column label="ações">
        <template #default="{ row: order }">
          <el-popconfirm
            @confirm="updateOrder({ uid: order.uid, should_charge: false })"
            title="
              Tem certeza?
            "
            ><template #reference>
              <el-button onlyIcon type="danger" plain>
                <i
                  class="el-icon-close"
                ></i></el-button></template></el-popconfirm
        ></template>
      </el-table-column>
    </el-table>
    <order-payment-modal
      :showModal="showOrderPaymentModal"
      @should-update="fetchOrder()"
      @close-modal="showOrderPaymentModal = false"
      :order="order"
    ></order-payment-modal>
    <finalize-order-payment-modal
      :showModal="showFinalizeOrderPaymentModal"
      @update-payment="checkPayment"
      :payment="orderPayment"
      @close-modal="showFinalizeOrderPaymentModal = false"
      title="Baixar pagamento"
      subtitle="Informe o montante que foi pago"
    ></finalize-order-payment-modal>
  </el-card>
</template>

<script>
import BaseInput from "../components/BaseInput.vue";
import OrderService from "../services/orders";
import {
  currencyFormatter,
  timeFormatter,
  dateFormatter,
} from "../utils/formatters";
import { notifySuccess } from "../utils/notifiers";
import OrderPaymentModal from "./modals/OrderPaymentModal.vue";
import FinalizeOrderPaymentModal from "./modals/FinalizeOrderPaymentModal";
export default {
  name: "ChargeOrder",
  components: { BaseInput, OrderPaymentModal, FinalizeOrderPaymentModal },
  data: () => ({
    isLoading: false,
    searchOrder: null,
    isLoadingClearButton: false,
    showOrderPaymentModal: false,
    showFinalizeOrderPaymentModal: false,
    order: null,
    orders: null,
    orderPayment: null,
    currencyFormatter,
    timeFormatter,
    dateFormatter,
  }),
  computed: {
    OrderPayments() {
      return this.order?.payments;
    },
    OrdersPayments() {
      return this.orders?.map(({ payments }) => payments).flat();
    },
    OnlyUncheckedPayments() {
      return this.OrderPayments.filter((p) => !p.is_checked);
    },
    Orders() {
      return this.orders || [];
    },
  },
  mounted() {
    this.fetchOrders();
  },
  methods: {
    async removeAllOrdersFromCharge() {
      const orders = this.orders;
      this.isLoadingClearButton = true;

      for (let o in orders) {
        const order = orders[o];
        await this.updateOrder(
          {
            uid: order.uid,
            should_charge: false,
          },
          false,
          false
        );
      }

      this.fetchOrders();

      this.isLoadingClearButton = false;

      notifySuccess("Todos os pedidos foram removidos da cobrança");
    },
    sumTotalAmount() {
      return this.orders?.reduce((t, o) => t + Number(o.total_amount), 0) || 0;
    },
    openFinalizePaymentModal(payment) {
      this.showFinalizeOrderPaymentModal = true;
      this.orderPayment = payment;
    },
    openOrderPaymentModal() {
      this.showOrderPaymentModal = true;
    },
    async chargeOrder(){
      if(this.order?.uid){
        await this.updateOrder({ uid: this.order.uid, should_charge: true });
      } 
    },
    async fetchOrder() {
      if(!this.searchOrder) return;
      this.isLoading = true;

      const {
        orders: [order],
      } = await OrderService().index({
        code: this.searchOrder,
      });

      if (order){
        this.order = order;
        this.chargeOrder();
        this.searchOrder = null;
        this.fetchOrders();
      }
      else this.order = null;

      this.isLoading = false;
    },
    async updateOrder(_, shouldRefetch = true, shouldNotify = true) {
      const { order } = await OrderService(_.uid).update(_);

      if (order && shouldNotify) notifySuccess("Pedido adicionado à cobrança");

      if (shouldRefetch) this.fetchOrders();
    },
    async fetchOrders() {
      this.isLoading = true;

      const { orders } = await OrderService().index({
        should_charge: true,
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
    sumTotalPayments(payments) {
      if (payments) return payments.reduce((t, p) => t + Number(p.amount), 0);
      else return this.OrderPayments?.reduce((t, p) => t + Number(p.amount), 0);
    },
    sumTotalPaidPayments(payments) {
      if (payments)
        return payments.reduce(
          (t, p) => (p.is_checked ? t + Number(p.amount) : t),
          0
        );
      else
        return this.OrderPayments?.reduce(
          (t, p) => (p.is_checked ? t + Number(p.amount) : t),
          0
        );
    },
  },
};
</script>
<style>
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
